import { navigate } from "gatsby";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  RealmAppProvider,
  useRealmApp,
  OnboardingRedirector,
  APP_ID,
} from "../../../components/realm_app";
import OnboardingNavbar from "../../../components/onboarding_navbar";
import { Footer } from "../../../components/footer";
import { getUrlParam } from "../../../utils";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { LogOutButton } from "../../../components/realm_app";
import { defaultPhoneInputValidation, validateName } from "../../../utils";
import WelcomeHeader from "../../../components/welcome_header";

const invalid_invite_code_error =
  "Invalid Invite URL. Please re-navigate to the signup " +
  "URL with invite code provided by your facilitator/program manager. It should look " +
  "something like 'app.wechampion.io/signup/?invite_code=<SomeCode>'";

const JoinCirclePage = () => {
  const realmApp = useRealmApp();
  var invite_code = getUrlParam("invite_code");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [loading, setLoading] = useState(
    invite_code == "" ? invalid_invite_code_error : ""
  );

  const handleJoinCircle = async () => {
    if (invite_code == "") {
      alert(invalid_invite_code_error);
      return;
    }

    setLoading("Joining circle...");

    try {
      if (!isPhoneValid) {
        throw "Invalid phone number. Please select a country code using the dropdown menu and fill in your phone number.";
      }
      validateName(name);

      const result = await realmApp.app.currentUser.functions.tryAddToCircleWithInviteLink(
        invite_code,
        name,
        phone
      );
      console.log(result);
      if (typeof result == "string") {
        alert(result);
      } else if ("matchedCount" in result && result["matchedCount"] == 1) {
        await realmApp.appRefreshCustomData();
        if (Boolean(realmApp.app.currentUser.customData.circle)) {
          console.log("circle successfully set, going to dashboard");
          navigate("/member/dashboard");
        } else {
          alert("something went wrong. Please refresh page and try again.");
        }
      } else {
        alert(
          "Could not save profile info. Please refresh page and try again."
        );
        console.log(result);
      }
    } catch (err) {
      alert(err);
    }

    setLoading("");
  };

  return (
    <>
      <div className="page">
        <OnboardingNavbar />
        <div className="page-content-center">
          <h3>Join Your Circle</h3>
        </div>
        <div className="page-content-center">
          <div className="page-content page-content-small">
            <br />
            <strong>INVITE CODE</strong>
            <input
              type="text"
              style={{ display: "table-cell", width: "100%" }}
              autoFocus
              value={invite_code}
              disabled
              // onChange={(e) => setCode(e.target.value)}
            />
            <br />
            <br />
            <strong>FULL NAME</strong>
            <input
              type="text"
              style={{ display: "table-cell", width: "100%" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <br />
            <strong>PHONE NUMBER</strong>
            <PhoneInput
              country={"us"}
              value={phone}
              inputProps={{
                required: true,
              }}
              isValid={defaultPhoneInputValidation}
              onChange={(phoneDigits, country) => {
                setPhone(`+${phoneDigits}`);
                setIsPhoneValid(
                  defaultPhoneInputValidation(phoneDigits, country)
                );
              }}
            />
            <br />
            <br />
            <Button
              className="light-green-button btn-block"
              onClick={() => handleJoinCircle()}
            >
              Next
            </Button>
            {/* <br /><br/>
            <LogOutButton /> */}
            {/* <br /> */}
          </div>
        </div>
        <div className="page-content-center">{loading}</div>
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <JoinCirclePage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
